import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Bots from './Bots'; // Importe o componente Bots
import logo from './logo.svg';
import "./css/bootstrap.css";
import "./main.css";
import "./fontawesome/css/all.css";
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';

function App() {
  useEffect(() => {
    var textOptions = ["Comunidades ", "FiveM ", "Equipes ", "Lojas "];
    var textIndex = 0;
    var blinkingText = document.getElementById("blinking-text");
    var isDeleting = false;
    var txt = '';
    var speed = 200;
    var waitAfterComplete = 3000;

    function typeWriter() {
      if (isDeleting && txt.length === 0) {
        isDeleting = false;
        textIndex = (textIndex + 1) % textOptions.length;
        speed = 200;
      }
      if (!isDeleting && txt.length === textOptions[textIndex].length) {
        isDeleting = true;
        speed = waitAfterComplete;
      } else {
        speed = 200;
      }

      txt = isDeleting ? textOptions[textIndex].substring(0, txt.length - 1) : textOptions[textIndex].substring(0, txt.length + 1);
      if (blinkingText) {
        blinkingText.textContent = txt;
      }
      setTimeout(typeWriter, speed);
    }

    typeWriter();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path='/' element={
              <div className="App">
              <body className='main home text-white'>
          <div className='container no-margin'>
          {/* <Navbar expand="lg" className='navbar-dark'>
                  <Navbar.Brand href="https://omnibots.store">
                    <img src={logo}></img>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto mt-2 mt-lg-0">
                      <Nav.Link id="active" as={Link} to="/"><i className="fa-regular fa-house"></i> Home</Nav.Link>
                      <Nav.Link id="link" as={Link} to="/bots"><i className="fa-regular fa-robot"></i> Nossos Bots</Nav.Link>
                      <Nav.Link id="link" href="https://github.com/OmniBotsBr"><i className="fa-brands fa-github"></i> Github</Nav.Link>
                    </Nav>
                    <Button className="btn btncolor btn-outline-success my-2 my-sm-0 btn-doc" href="https://docs.omnibots.store">Documentação</Button>
                  </Navbar.Collapse>
                </Navbar> */}

<nav class="navbar navbar-expand-lg navbar-dark">
                <a class="navbar-brand" href="#">
                  <img src={logo}/>
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                  <ul class="navbar-nav mx-auto mt-2 mt-lg-0">
                
                  </ul>
                <div class="my-2 my-lg-0">
                    <a class="btn btn-outline-success my-2 my-sm-0 btn-doc btncolor" href="https://docs.omnibots.store">Documentação</a>
                </div>
                </div>
              </nav>
          </div>
  
  
          <div class="content">
            <div class="container text-center">
              <h2><b><span class="gradient" >Bots Para&nbsp;<span id="blinking-text"></span></span><br></br></b></h2>
              <h3><b>100% Personalizados</b></h3>
                  <h4>Nunca foi tão facil ter um bot do jeito que você precisa<span class="gradient">!</span></h4>
                  <p id="window-size"></p>
                  <a class="btn btn2 btncolor" href='https://discord.gg/Yc4dZ5PfQw'><i class="fa-brands fa-discord"></i> ACESSAR NOSSO DISCORD</a>
            </div>
          </div>
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.1/dist/js/bootstrap.min.js" integrity="sha256-gOQJIa9+K/XdfAuBkg2ONAdw5EnQbokw/s2b8BqsRFg=" crossorigin="anonymous"></script>
          <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
        </body>
      </div>
        } />
        <Route path='/bots' element={<Bots/>}/>
      </Routes>
    </Router>
    
  );
}

export default App;
