import React from 'react';
import logo from './logo.svg';
import icon from './img/logo.png'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';

function Bots() {
  return (
    <div>
        <body className='main text-white'>
            <div class="container">
                <div class="row navbar-top">
                    
                </div>
            <Navbar expand="lg" className='navbar-dark'>
                  <Navbar.Brand href="https://omnibots.store">
                    <img src={logo}></img>
                  </Navbar.Brand>
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto mt-2 mt-lg-0">
                      <Nav.Link id="link" href="https://omnibots.store"><i className="fa-regular fa-house"></i> Home</Nav.Link>
                      <Nav.Link id="active" as={Link} to="/bots"><i className="fa-regular fa-robot"></i> Nossos Bots</Nav.Link>
                      <Nav.Link id="link" href="https://github.com/OmniBotsBr"><i className="fa-brands fa-github"></i> Github</Nav.Link>
                    </Nav>
                    <Button className="btn btncolor btn-outline-success my-2 my-sm-0 btn-doc" href="https://docs.omnibots.store">Documentação</Button>
                  </Navbar.Collapse>
                </Navbar>
            </div>

            <div class="content">
                <div class="container text-center">
                    <div class="row bots">

                        <div class="col-lg-4">
                            <div class="botborder">
                                <img src={icon} width="30%" class="boticon"></img>
                                <h2 class="nome">Omni<span class="gradient bold">Community</span> </h2>
                                <span class="destaque">Nosso bot para gerenciar servidores de comunidades</span>
                                <hr></hr>
                                <div class="funcao">
                                    <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Sistema de Logs</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Segurança Raid e Flood</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Gerenciamneto de Cargos</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Diversão e Entretenimento</span>
                                </div>
                                <div class="end"></div>
                                <a class="btn btn-outline-success my-2 my-sm-0 btn-invite btnbranco" href="https://docs.omnibots.store">Ver Documentação</a>
                                <div class="end2"></div>
                                <a class="btn btn-outline-success my-2 my-sm-0 btn-invite btncolor" href="https://docs.omnibots.store">Adicionar Bot</a>
                                <div class="end"></div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="botborder">
                                <img src={icon} width="30%" class="boticon"></img>
                                <h2 class="nome">Omni<span class="gradient bold">LOL</span> </h2>
                                <span class="destaque">Bot feito para servidores de League of Legends</span>
                                <hr></hr>
                                <div class="funcao">
                                    <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Pensar Funções</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Pensar Funções</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Pensar Funções</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Pensar Funções</span>
                                </div>
                                <div class="end"></div>
                                <a class="btn btn-outline-success my-2 my-sm-0 btn-invite btnbranco" href="https://docs.omnibots.store">Ver Documentação</a>
                                <div class="end2"></div>
                                <a class="btn btn-outline-success my-2 my-sm-0 btn-invite btncolor" href="https://docs.omnibots.store">Adicionar Bot</a>
                                <div class="end"></div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="botborder">
                                <img src={icon} width="30%" class="boticon"></img>
                                <h2 class="nome">Omni<span class="gradient bold">Beta</span> </h2>
                                <span class="destaque">Tenha acesso antecipado a recursos do Community</span>
                                <hr></hr>
                                <div class="funcao">
                                    <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Sistema de Logs</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Segurança Raid e Flood</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Gerenciamneto de Cargos</span>
                                </div>
                                <div class="funcao">
                                        <span class="f-texto"> <i class="fa-solid fa-circle-check gradient"></i> Diversão e Entretenimento</span>
                                </div>
                                <div class="end"></div>
                                <a class="btn btn-outline-success my-2 my-sm-0 btn-invite btnbranco" href="https://docs.omnibots.store">Ver Documentação</a>
                                <div class="end2"></div>
                                <a class="btn btn-outline-success my-2 my-sm-0 btn-invite btncolor" href="https://docs.omnibots.store">Adicionar Bot</a>
                                <div class="end"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="container">
                <dic class="row">
                        <div class="col-lg-12">
                        <h2 class="titulo gradient">
                            MODULOS E OPÇÕES ADICIONAIS
                        </h2>
                        <p class="sutitulo">Opções de customização e comandos adicionais para os bots</p>
                        </div>
                    </dic>
                    <div class="row borderadicionais">
                        <div class='col-lg-9 adicionais'>
                            <h2>
                                Modificação<span class="gradient bold"> Base</span> 
                            </h2>
                            <span class="info">A modificação base serve criar uma versão personalizada do bot para serem implementadas as outras funções adicionais, alem disso com ela é possivel definir um nome e avatar personalizados para seu bot<span class="gradient">.</span></span><br/>
                            <span class="requer"><span class="branco">Disponivel para: </span><span class="gradient bold">OmniCommunity</span> e <span class="gradient bold">OmniLOL</span></span>
                        
                        </div>
                        <div class="col-lg-3 align-self-center esquerda">
                           <div class="plano">
                                <h2 class="preço gradient">R$ 14,90</h2>
                                <span className='titulo-plano'>por mês</span>
                           </div>
                        </div>
                    </div>
                    <div class="end"></div>
                    <div class="row borderadicionais">
                        <div class='col-lg-9 adicionais'>
                            <h2>
                                Omni<span class="gradient bold"> Tickets</span> 
                            </h2>
                            <span class="info">Nosso sistema de Tickets com sistema de categorias e cargos, que ao finalizar o atendimento salva o transcript do atendimento e envia para o usuario<span class="gradient">.</span></span><br/>
                            <div class="end2"/>
                            <span class="requer"><span class="branco">Nescessario: </span>Modificação Base<br/><span class="branco">Disponivel para: </span><span class="gradient bold">OmniCommunity</span></span>
                        </div>
                        <div class="col-lg-3 align-self-center esquerda">
                           <div class="plano">
                                <h2 class="preço gradient">R$ 29,90</h2>
                                <span className='titulo-plano'>por mês</span>
                           </div>
                        </div>
                    </div>
                    <div class="end"></div>
                    <div class="row borderadicionais">
                        <div class='col-lg-9 adicionais'>
                            <h2>
                                Omni<span class="gradient bold"> Music</span> 
                            </h2>
                            <span class="info">Nosso sistema de musica completo para os membros do seu servidor<span class="gradient">.</span></span><br/>
                            <div class="end2"/>
                            <span class="requer"><span class="branco">Nescessario: </span>Modificação Base<br/><span class="branco">Disponivel para: </span><span class="gradient bold">OmniCommunity</span> e <span class="gradient bold">OmniLOL</span></span>
                        </div>
                        <div class="col-lg-3 align-self-center esquerda">
                           <div class="plano">
                                <h2 class="preço gradient">R$ 4,90</h2>
                                <span className='titulo-plano'>por mês</span>
                           </div>
                        </div>
                    </div>
                    <div class="end"></div>
                    <div class="row borderadicionais">
                        <div class='col-lg-9 adicionais'>
                            <h2>
                                Omni<span class="gradient bold"> Vip</span> 
                            </h2>
                            <span class="info">Nosso sitema para gerenciar vips de membros do seu servidor com sistema de permições, duração, set e muito mais<span class="gradient">.</span></span><br/>
                            <div class="end2"/>
                            <span class="requer"><span class="branco">Nescessario: </span>Modificação Base<br/><span class="branco">Disponivel para: </span><span class="gradient bold">OmniCommunity</span> e <span class="gradient bold">OmniLOL</span></span>
                        </div>
                        <div class="col-lg-3 align-self-center esquerda">
                           <div class="plano">
                                <h2 class="preço gradient">R$ 14,90</h2>
                                <span className='titulo-plano'>por mês</span>
                           </div>
                        </div>
                    </div>
                    <div class="end"/>
                    <div class="text-center">
                    <button class="btn btn2 btncolor"><i class="fa-brands fa-discord"></i>  COMPRAR MODULOS</button>
                    </div>
                    <div class="end4"/>
                </div>
                <div class="container text-center">
                    <hr/>
                    <div class="row">
                        <div class="col-lg-12">
                        <p class="copy">©2024 - Todos os Direitos Reservados a <span class="gradient">OmniBots</span> | Feito com <span class="gradient">❤</span> no RS e RJ</p>
                        </div>
                        </div>
                </div>
            </div>
        </body>
    </div>
  );
}

export default Bots;